<template>
  <v-dialog
    v-model="opened"
    width="700"
  >
    <v-flex xs12>
      <v-card :loading="loading" color="#fff" class="pa-3">
        <v-flex xs12>
          <div class="expande-horizontal wrap">
            <v-flex xs12>
              <v-form ref="formMinhaLoja">
                <v-flex xs12>
                  <div class="fonte expande-horizontal">
                    <div class="expande-horizontal column" style="flex-wrap: nowrap;">
                        <h1 style="font-size: 30pt; color: #333;" class="fonte fonte-bold">
                          {{ $helper.formataSaldo(subscription.plan_id.value_cents) }}
                        </h1>
                        <span style="font-size: 14pt; margin-top: -18px;" class="fonte grey--text">
                          Plano {{ subscription.plan_id.name }}
                        </span>
                        <span class="fonte orange--text"
                          >Sua assinatura está
                          {{ filterStatusSubscription(subscription.current_status) }}</span
                        >
                        <span v-if="warning" class="fonte red--text">
                          {{ message }}
                        </span>
                      </div>
                      <v-spacer></v-spacer>
                      <v-btn @click="close" icon>
                        <v-icon color="orange" size="30">mdi-close</v-icon>
                      </v-btn>
                  </div>
                  <v-divider></v-divider>
                  <v-timeline dense align-top>
                    <v-timeline-item fill-dot small :icon-color="$theme.primary" icon="mdi-flag" color="#f2f2f2">
                      <span class="fonte grey--text">você entrou <span style="font-size: 14pt; color: #333;" class="fonte-bold">{{ $moment(subscription.created_at).format('DD/MM/YYYY [às] HH:mm') }}</span> </span>
                    </v-timeline-item>
                    <v-timeline-item class="mt-3" v-if="subscription.trial_end" fill-dot small :icon-color="$theme.primary" icon="mdi-flag" color="#f2f2f2">
                      <span class="fonte grey--text">o período de teste acaba <span style="font-size: 14pt; color: #333;" class="fonte-bold">{{ $moment(subscription.trial_end, 'YYYY-MM-DD').format('DD/MM/YYYY') }}</span> </span>
                    </v-timeline-item>

                    <!-- <v-timeline-item v-if="!subscription.trial_end && $moment().isAfter($moment(subscription.trial_end, 'YYYY-MM-DD').endOf('day'))" fill-dot small :icon-color="$theme.primary" icon="mdi-flag" color="#f2f2f2"> -->
                    <v-timeline-item class="mt-3" fill-dot small :icon-color="$theme.primary" icon="mdi-flag" color="#f2f2f2">
                      <span style="font-size: 14pt;" class="fonte-bold black--text">Confira faturas em aberto</span>
                      <v-list style="max-width: 100%;" color="transparent" class="pa-0 ma-0 fonte column expande-horizontal">
                        <v-list-item
                        @click="openPaymentLink(charge)"
                          class="item-list-charge mb-3"
                          v-for="charge in subscription.asaas_payments"
                          :key="charge._id"
                          v-show="charge.status !== 'RECEIVED' && charge.status !== 'RECEIVED_IN_CASH'"
                        >
                          <v-list-item-content>
                            <v-list-item-title class="blue--text fonte-bold font-weight-bold">
                              <span style="font-size: 14pt;">
                                {{ $helper.formataSaldo(charge.value) }}
                              </span>
                            </v-list-item-title>
                            <v-list-item-subtitle class="grey--text">
                              <span v-if="$vuetify.breakpoint.lgAndUp" style="font-size: 12pt;">
                                Esta fatura tem vencimento em {{ $moment(charge.dueDate, 'YYYY-MM-DD').format('DD/MM/YYYY') }}, clique para visualizar.
                              </span>
                              <span v-else style="font-size: 12pt;">
                                Vence {{ $moment(charge.dueDate, 'YYYY-MM-DD').format('DD/MM/YYYY') }}
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn @click="openPaymentLink(charge)" depressed class="fonte text-capitalize" icon>
                              <v-icon size="21" :color="$theme.primary">
                                mdi-open-in-new
                              </v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-timeline-item>
                  </v-timeline>
                
                </v-flex>
              </v-form>
            </v-flex>
          </div>
        </v-flex>
      </v-card>
    </v-flex>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { VueCropper } from "vue-cropper";
import LottieAnimation from "lottie-web-vue";
import moment from 'moment';

export default {
  data() {
    return {
      moment,
      opened: false,
      subscription: {
        methods: "",
        plan_id: {},
        creation_of_next_charge: ""
      },
      message: "",
      warning: false,
      loading: false
    };
  },
  components: {
    VueCropper,
    LottieAnimation
  },
  computed: {
    ...mapGetters(["getLoggedUser"]),
    methodSelected() {
      return this.subscription.method || "";
    },
    daysLeft() {
      const expires =
        this.getLoggedUser.tenant[0].active_plan.expires_at || new Date();
      const days = this.$moment(expires).diff(this.$moment(), "days");
      return days;
    },
    dateExpire() {
      const expires =
        this.getLoggedUser.tenant[0].active_plan.expires_at || new Date();
      return this.$moment(expires).format("DD/MM/YYYY [às] HH:mm");
    }
  },
  methods: {
    filterStatusSubscription(val) {
      const nextCharge = this.$moment(this.subscription.creation_of_next_charge).format("DD/MM/YY")
      switch (val) {
        case "trial":
          return `ativa para avaliação até ${nextCharge}`;
        case "active":
          return "ativa";
        default:
          return "Pendente";
      }
    },
    openPaymentLink(charge) {
      window.open(charge.invoiceUrl, "_blank");
    },
     verifySub() {
      this.loading = true;
      this.$run('subscription/view-my-subscription')
        .then(res => {
          this.subscription = res.data;
          this.loading = false;
          if (res.data.current_status == 'overdue' || res.data.current_status == 'canceled' ) {
            this.message = 'Sua assinatura está pendente, por favor, realize o pagamento para ativar sua assinatura.'
            this.opened = true;
            this.warning = true;
          }
        })
        .catch(err => {
          this.loading = false;
          console.log(err)
        });
    },
    open() {
      this.opened = true;
      // this.loading = true;
      // this.$run('subscription/view-my-subscription')
      //   .then(res => {
      //     this.subscription = res.data;
      //     this.loading = false;
      //   })
      //   .catch(err => {
      //     this.loading = false;
      //     console.log(err)
      //   });
    },
    saveSubscription() {
      this.$run('subscription/activate-my-subscription', this.subscription)
        .then(res => {
        })
        .catch(err => console.log(err));
    },
    close() {
      this.opened = false;
    },
    selectPaymentForm(method) {
      this.subscription = {
        ...this.subscription,
        method
      };
    }
  }
}
</script>

<style>
.store-layout {
  position: relative;
  width: 100%;
  max-width: 100%;
  min-height: 120px;
  cursor: pointer;
}

.store-cover {
  width: 100%;
  min-height: 220px;
  border-radius: 6px;
}

.profile-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
}

.store-profile {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 50%;
  object-fit: cover;
  background: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.store-profile:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.item-list-charge {
  background: #e6e4e4 !important;
  border-radius: 6px;
}
.change-method-icon-container {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #f2f2f2;
}
.method-selected {
  box-shadow: -1px -2px 20px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -1px -2px 20px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px -2px 20px 2px rgba(0, 0, 0, 0.1);
  background: #9206b5 !important;
}
.change-method-icon-container {
  cursor: pointer;
}
</style>
