<template>
  <layout
    :title="$route.name"
    :rotas="rotas"
    :options="options"
    :toolbar_off="true"
  >
    <ModalSubscription ref="modalSub" />
    <div
      class="expande-horizontal wrap"
      :class="{ 'pa-3': $vuetify.breakpoint.lgAndUp }"
      :style="
        `min-height: 100vh; max-height: 100vh; overflow: auto; justify-content: flex-start; background: ${
          dark ? '#222' : '#fff'
        }; overflow: ${$vuetify.breakpoint.lgAndUp ? 'hidden' : 'auto'}`
      "
    >
      <v-flex xs12>
        <div class="pt-0 expande-horizontal column wrap">
          <div class="expande-horizontal" style="height: 35px;">
            <h1
              style="font-size: 17pt;"
              :class="{
                'white--text': dark,
                'black--text': !dark
              }"
              class="fonte-bold font-weight-light mb-0 ml-2 animate__animated animate__fadeInDown animate__delay-2s"
            >
              <span style="font-size: 14pt;">🌴</span>
              <span class="text-uppercase">
                {{ getLoggedUser.tenant[0].nome.split(" ")[0] }}
                <!-- {{ getLoggedUser.nome.split(" ")[0] }} -->
              </span>
              <v-btn
                depressed
                v-if="getLoggedUser.admin"
                class="fonte-bold mb-1 ml-1"
                color="#333"
                dark
                x-small
                @click="$refs.modalSub.open()"
                >Ver assinatura</v-btn
              >
            </h1>
            <span
              v-if="showWelcome"
              :class="{
                'white--text': dark,
                'black--text': !dark
              }"
              style="font-size: 22pt;"
              class="message fonte-bold animate__animated animate__fadeInUp"
              >Bem vindo(a)!</span
            >
            <v-spacer></v-spacer>
            <span class="fonte-bold mt-1 mx-2"
              :class="{
                'green--text': (pingTime / 2) <= 200,
                'orange--text': (pingTime / 2) > 200 && (pingTime / 2) <= 400,
                'red--text': (pingTime / 2) > 400,
              }"
            >{{ pingTime / 2 }}ms</span>

            <v-switch
              v-model="dark"
              :label="dark ? '🌞' : '🌜'"
              :dark="dark"
              hide-details
              class="mr-3"
              :color="$theme.secondary"
              style="margin-top: -0px;"
              @change="saveStateTheme($event)"
            ></v-switch>
          </div>
          <!-- <v-btn
            :style="`border: 2px solid ${$theme.primary};`"
            v-if="getLoggedUser.admin"
            class="ma-3 dark-font"
            color="#e5e5e5"
            @click="$refs.modalSub.open()"
            >Ver assinatura</v-btn
          > -->
          <div
            id="container-menus"
            class="animate__animated animate__fadeIn animate__delay-1s expande-horizontal pl-2 wrap"
          >
            <v-slide-group
              :show-arrows="false"
              ref="sliderContainer"
              class="slider-container"
            >
              <v-slide-item
                v-for="(menu, index) in menus"
                v-show="menu.permission()"
                :key="`item-menu-${index}`"
              >
                <v-card
                  width="80"
                  :color="dark ? '#444' : '#e5e5e5'"
                  class="mr-2 mb-2 py-2 px-2"
                  @click="$router.push(menu.route)"
                  :dark="dark"
                  style="border-radius: 12px;"
                >
                  <div
                    style="min-height: 60px; max-height: 60px; position: relative;"
                    class="expande-horizontal item-menu-mini column centraliza"
                  >
                    <v-icon
                      :color="dark ? $theme.secondary : $theme.primary"
                      size="26"
                      >{{ menu.icon }}</v-icon
                    >
                    <v-spacer></v-spacer>
                    <span
                      style="position: absolute; bottom: 0px;"
                      class="fonteMini text-center"
                      >{{ menu.name }}</span
                    >
                  </div>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </div>
          <!-- <div class="expande-horizontal centraliza">
            <cosmos-filter-date v-model="dateFilter"></cosmos-filter-date>
          </div> -->
          <div
            class="expande-horizontal wrap animate__animated animate__fadeIn animate__delay-1s"
          >
            <v-flex
              v-if="
                get_permission_user['contabilidade-de-caixas'] &&
                  get_permission_plan.includes('contabilidade')
              "
              xs12
              class="pa-2"
              md3
            >
              <v-card
                style="border-radius: 12px;max-height: 40vh;"
                :color="dark ? '#444' : '#e5e5e5'"
                :dark="dark"
                v-if="chartValues"
                hover
                class="pa-2 pl-0 pt-0 expande-horizontal column animate__animated animate__fadeIn"
              >
                <h3 class="fonte-bold ma-2">
                  💰 Estatística de Pagamento (30 dias)
                </h3>
                <Chart
                  :dark="dark"
                  type="doughnut"
                  ref="chartValue"
                  :backgroundColor="[
                    'rgb(255, 205, 86, 0.8)',
                    'rgb(255, 99, 132)',
                    'rgb(75, 192, 192)',
                    'rgb(201, 203, 207)'
                  ]"
                  description="Valor"
                  :labels="valuesPaid30Days.paymentTypes"
                  :dataChart="valuesPaid30Days.paymentValues"
                  :id="'chart-1'"
                  :height="200"
                />
              </v-card>
            </v-flex>
            <v-flex
              v-if="
                get_permission_user['gestor-de-vendas'] &&
                  get_permission_plan.includes('gestor-de-venda')
              "
              xs12
              class="pa-2"
              md6
            >
              <v-card
                style="border-radius: 12px; max-height: 40vh;"
                :color="dark ? '#444' : '#e5e5e5'"
                :dark="dark"
                v-if="chartSales"
                hover
                class="pa-2 pl-0 pt-0 expande-horizontal column animate__animated animate__fadeIn"
              >
                <h3 class="fonte-bold ma-2">📈 Estatística últimos 30 dias</h3>
                <Chart
                  :dark="dark"
                  type="line"
                  ref="chartSales"
                  backgroundColor="rgb(255, 205, 86, 0.6)"
                  description="Orçamentos"
                  :labels="salesLast30Days.days"
                  :dataChart="salesLast30Days.valuesForDays"
                  :id="'chart-2'"
                  :height="200"
                />
              </v-card>
            </v-flex>
            <v-flex
              v-if="
                get_permission_user['gestor-de-vendas'] &&
                  get_permission_plan.includes('gestor-de-venda')
              "
              xs12
              md3
              class="pa-2"
            >
              <v-card
                style="max-height: 40vh;border-radius: 12px;"
                :color="dark ? '#444' : '#e5e5e5'"
                :dark="dark"
                hover
                class="pa-3 expande-horizontal wrap column windows-style-content"
              >
                <span class="fonte-bold" style="font-size: 14pt;"
                  >📐 {{ salesToday.length }} Orçamentos de hoje</span
                >
                <v-flex xs12>
                  <div
                    class="expande-horizontal centraliza"
                    style="max-height: 200px; min-height: 200px;"
                  >
                    <v-list
                      v-if="salesToday.length > 0"
                      dense
                      style="min-width: 100%; min-height: 200px; max-height: 200px; overflow: auto;"
                      :dark="dark"
                      :color="dark ? '#444' : '#e5e5e5'"
                      class="ma-0 pa-0 windows-style-content"
                    >
                      <v-list-item
                        @click="
                          $router.push(`/gestordepedidos?t_id=${sale._id}`)
                        "
                        v-for="sale in salesToday"
                        :key="sale._id"
                        class="pa-0 ma-0 pr-2 animate__animated animate__fadeInUp"
                        style="border-radius: 6px; margin-bottom: 6px; max-height: 200px;"
                      >
                        <v-list-item-content>
                          <v-list-item-title class="fonte-bold">
                            {{ sale.compra.cliente.nome }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{
                              $helper.formataSaldo(sale.compra.preco_total || 0)
                            }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-chip
                            dark
                            x-small
                            class="fonte-bold"
                            :color="$theme.primary"
                          >
                            <span style="font-size: 14pt; margin-top: 6px;">{{
                              $moment(sale.created_at).format("HH:mm")
                            }}</span>
                          </v-chip>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                    <div v-else class="empty expande-horizontal centraliza column">
                      📐
                      <span class="fonte-bold">Nada por hoje ainda!</span>
                    </div>
                  </div>
                </v-flex>
              </v-card>
            </v-flex>
          </div>
        </div>
      </v-flex>
      <v-flex class="animate__animated animate__fadeIn animate__delay-1s" xs12>
        <div
          class="expande-horizontal centraliza animate__animated animate__fadeIn wrap"
        >
          <v-flex xs12 md6 class="pa-2">
            <v-card
              :color="dark ? '#444' : '#e5e5e5'"
              :dark="dark"
              style="border-radius: 12px;"
              class="pa-3 expande-horizontal wrap column"
            >
              <div class="expande-horizontal centraliza">
                <span class="fonte-bold" style="font-size: 16pt;"
                  >📰 Últimas atualizações</span
                >
                <v-spacer></v-spacer>
                <v-switch
                  small
                  hide-details
                  class="fonte fonteMini pa-0 ma-0"
                  :color="$theme.primary"
                  v-model="onlyUnreadeds"
                >
                </v-switch>
                <span
                  style="cursor: pointer;"
                  @click="onlyUnreadeds = !onlyUnreadeds"
                  class="fonteMini fonte grey--text"
                >
                  não lidas
                </span>
              </div>
              <v-flex xs12>
                <div
                  class="expande-horizontal"
                  style="max-height: 44vh; min-height: 44vh; overflow: auto;"
                >
                  <v-flex xs12>
                    <v-list
                      class="fonte windows-style-content"
                      v-if="notifications.length > 0"
                      width="100%"
                      :dark="dark"
                      :color="dark ? '#444' : '#e5e5e5'"
                      style="max-height: 40vh; overflow: auto;"
                    >
                      <template
                        v-for="(notification, i) in onlyUnreadeds
                          ? notificationsUnreadeds
                          : notifications"
                      >
                        <v-list-item
                          class="item-list-notification mr-2 animate__animated animate__fadeInUp"
                          style="border: none; border-radius: 0px;"
                          :key="notification._id"
                          v-if="onlyUnreadeds ? !notification.readed : true"
                          @click="abre_modal_view_notification(notification)"
                        >
                          <!-- <v-avatar size="31" class="mr-3" color="#f2f2f2">
                            <v-icon :color="$theme.primary" size="21">
                              {{ getTypeLinkByTypeNotification(notification).icon }}
                            </v-icon>
                          </v-avatar> -->
                          <v-list-item-content>
                            <v-list-item-title>
                              <span
                                :class="{
                                  'unreaded-notification font-weight-bold': !notification.readed
                                }"
                              >
                                {{
                                  getTypeLinkByTypeNotification(notification)
                                    .title
                                }}
                              </span>
                            </v-list-item-title>
                            <span
                              class="fonte grey--text mt-1"
                              :class="{
                                'unreaded-notification': !notification.readed
                              }"
                            >
                              {{ notification.description }}
                            </span>
                            <div class="expande-horizontal">
                              <span
                                class="fonte mt-1"
                                :style="
                                  `color: ${
                                    notification.readed
                                      ? 'grey'
                                      : $theme.secondary
                                  }`
                                "
                                :class="{
                                  'unreaded-notification': !notification.readed
                                }"
                                style="font-size: 12pt;"
                              >
                                {{
                                  $moment(notification.created_at).fromNow()
                                }}</span
                              >
                              <span
                                class="fonte mt-1"
                                :style="
                                  `color: ${
                                    notification.readed
                                      ? 'grey'
                                      : $theme.secondary
                                  }`
                                "
                                v-if="notification.readed"
                                :class="{
                                  'unreaded-notification': !notification.readed
                                }"
                                style="font-size: 12pt;"
                              >
                                , foi lida
                                {{
                                  $moment(notification.readDate).format(
                                    "DD/MM/YY [às] HH:mm"
                                  )
                                }}
                              </span>
                              <span
                                class="fonte fonteMini mt-1"
                                :style="`color: ${$theme.secondary}`"
                                v-else
                                :class="{
                                  'unreaded-notification': !notification.readed
                                }"
                                style="font-size: 12pt;"
                              >
                                , não lida ainda.
                              </span>
                              <v-icon
                                class="ml-2"
                                :class="{
                                  'unreaded-notification': !notification.readed
                                }"
                                color="orange"
                                size="15"
                                v-if="!notification.readed"
                                >mdi-check</v-icon
                              >
                              <v-icon
                                class="ml-2"
                                :class="{
                                  'unreaded-notification': !notification.readed
                                }"
                                color="green"
                                size="15"
                                v-if="notification.readed"
                                >mdi-check-all</v-icon
                              >
                            </div>
                          </v-list-item-content>
                          <!-- <v-list-item-action>
                                
                              </v-list-item-action> -->
                        </v-list-item>
                        <!-- <v-divider :key="`divider-${i}`"></v-divider> -->
                      </template>
                    </v-list>
                    <div
                      v-if="notifications.length === 0"
                      style="border: 1px solid #cbc7c7; min-height: 40vh;border-radius: 6px;"
                      class="fonte mt-2 expande-horizontal centraliza column"
                    >
                      <v-avatar class="mb-3" :color="$theme.primary">
                        <v-icon color="#f2f2f2">mdi-bell-outline</v-icon>
                      </v-avatar>
                      <span :style="`color: ${$theme.primary}`" class="fonte"
                        >Nada por aqui</span
                      >
                      <span class="text-center grey--text fonteMini fonte">
                        Novas notificações aparecerão aqui
                      </span>
                    </div>
                  </v-flex>
                </div>
              </v-flex>
            </v-card>
          </v-flex>
          <v-flex v-if="false" xs12 md3 class="pa-2">
            <v-card
              class="pa-3"
              :color="dark ? '#444' : '#e5e5e5'"
              :dark="dark"
              style="border-radius: 12px; min-height: 50vh; max-height: 50vh; overflow: hidden;"
            >
              <h3 class="fonte-bold mr-3">
                📑 Fluxo de contas
              </h3>
              <v-list
                class="fonte windows-style-content"
                style="max-height: 43vh; overflow: auto;"
                color="transparent"
                :dark="dark"
              >
                <v-list-item
                  class="px-0"
                  v-for="(userRank, i) in rankingUsers"
                  :key="userRank.user._id"
                >
                  <v-avatar
                    size="35"
                    class="mr-2"
                    :color="dark ? '#444' : '#e5e5e5'"
                  >
                    <h3>{{ i + 1 }}º</h3>
                  </v-avatar>
                  <!-- <h3 class="fonte-bold mr-3">{{ userRank.sales.length }}</h3> -->
                  <v-list-item-content>
                    <v-list-item-title>{{
                      userRank.user.nome
                    }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ userRank.sales.length }} Propostas
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-flex>
          <v-flex xs12 class="pa-2" md3>
            <v-card
              class="pa-3"
              :color="dark ? '#444' : '#e5e5e5'"
              :dark="dark"
              style="border-radius: 12px; min-height: 50vh; max-height: 50vh; overflow: auto;"
            >
              <h3 class="fonte-bold">📌 Agenda de Hoje</h3>
              <v-tabs
                v-model="tabCalendar"
                centered
                grow
                background-color="transparent"
              >
                <v-tab
                  class="fonte-bold"
                  v-if="
                    get_permission_user['agenda'] &&
                      get_permission_plan.includes('agenda')
                  "
                  >Geral</v-tab
                >
                <v-tab
                  class="fonte-bold"
                  v-if="
                    get_permission_user['minha-agenda'] &&
                      get_permission_plan.includes('minha-agenda')
                  "
                  >Para mim</v-tab
                >
              </v-tabs>
              <v-list
                class="px-0 ma-0 fonte windows-style-content pr-2"
                color="transparent"
                v-if="externalMovimentationsByTab.length"
                style="max-height: 30vh; overflow: auto;"
              >
                <v-list-item
                  @click="openEvent(event)"
                  v-for="event in externalMovimentationsByTab"
                  :key="event._id"
                  class="px-2 mx-0 list-events-item-selected"
                >
                  <v-icon
                    class="mr-3"
                    size="23"
                    :color="getColor(event.status)"
                    >{{ getIcon(event.status) }}</v-icon
                  >

                  <v-list-item-content>
                    <v-list-item-title>
                      <span
                        style="font-size: 14pt;"
                        class="fonte-bold"
                        :class="{
                          'white--text': dark,
                          'black--text': !dark
                        }"
                        >{{ event.type | typeFilter }}</span
                      >
                      <span style="font-size: 10pt;" class="grey--text ml-2"
                        >{{ event.visit_date }} {{ event.visit_hour }}</span
                      >
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text">
                      {{ event.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      text
                      dark
                      :color="getColor(event.status)"
                      x-small
                      depressed
                      rounded
                    >
                      <span class="fonte-bold" style="padding-top: 2px;">{{
                        getStatus(event.status)
                      }}</span>
                      <!-- <v-icon size="15">
                        {{ getIcon(event.status) }}
                      </v-icon> -->
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <div
                v-else
                class="expande-horizontal centraliza column"
                style="height: 260px; border-radius: 12px; margin-top: 6px;"
              >
                📌
                <span
                  :class="{
                    'white--text': dark,
                    'grey--text': !dark
                  }"
                  class="fonte-bold mt-2"
                  >Não há agendamentos para hoje!</span
                >
              </div>
            </v-card>
          </v-flex>
          <v-flex xs12 md3 class="pa-2">
            <v-card
              class="pa-3"
              :color="dark ? '#444' : '#e5e5e5'"
              :dark="dark"
              style="border-radius: 12px; min-height: 50vh; max-height: 50vh; overflow: hidden;"
            >
              <h3 class="fonte-bold mr-3">
                🏅 Ranking
              </h3>
              <v-list
                class="fonte windows-style-content"
                style="max-height: 43vh; overflow: auto;"
                color="transparent"
                :dark="dark"
              >
                <v-list-item
                  class="px-0 pr-2"
                  v-for="(userRank, i) in rankingUsers"
                  :key="userRank.user._id"
                >
                  <v-avatar
                    size="35"
                    class="mr-2"
                    :color="dark ? '#444' : '#e5e5e5'"
                  >
                    <h3>{{ i + 1 }}º</h3>
                  </v-avatar>
                  <!-- <h3 class="fonte-bold mr-3">{{ userRank.sales.length }}</h3> -->
                  <v-list-item-content>
                    <v-list-item-title>{{
                      userRank.user.nome
                    }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ userRank.sales.length }} Propostas
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-avatar size="36" color="#f2f2f2">
                      <v-icon
                        v-if="!userRank.user.profile_image"
                        :color="$theme.primary"
                        >mdi-account-circle-outline</v-icon
                      >
                      <v-img v-else :src="userRank.user.profile_image"></v-img>
                    </v-avatar>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </v-flex>
        </div>
      </v-flex>
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { driver } from "driver.js";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import ModalSubscription from "@/apps/dashboard/components/ModalSubscription.vue";
import Chart from "@/apps/27#Caixas/components/Chart.vue";
import axios from "axios";

export default {
  components: {
    ModalSubscription,
    Chart
  },
  data() {
    return {
      pingTime: 0,
      timeInit: 0,
      tabCalendar: 0,
      showWelcome: true,
      chartValues: true,
      chartSales: true,
      dark: false,
      dateFilter: "",
      notifications: [],
      onlyUnreadeds: false,
      rotas: [
        {
          name: "Início",
          path: "/dashboard"
        }
      ],
      driver: null,
      options: [
        {
          nome: "Iniciar guia",
          action: () => this.driverStart(),
          icon: "mdi-arrow-right-bold-outline"
        }
      ],

      menus: [
        {
          icon: "mdi-view-dashboard-outline",
          name: "Dashboard",
          route: "/dashboard",
          permission: () =>
            this.get_permission_user["dashboard"] &&
            this.get_permission_plan.includes("dashboard")
        },
        {
          icon: "mdi-cash-register",
          name: "Caixa",
          route: "/atendimento",
          permission: () =>
            this.get_permission_user["caixa"] &&
            this.get_permission_plan.includes("caixa")
        },
        {
          icon: "mdi-arrow-decision",
          name: "Gestor de Vendas",
          route: "/gestordepedidos",
          permission: () =>
            this.get_permission_user["gestor-de-vendas"] &&
            this.get_permission_plan.includes("gestor-de-venda")
        },
        {
          name: "divider",
          permission: () => false
        },
        {
          icon: "mdi-calendar-badge-outline",
          name: "Minha Agenda",
          route: "/minha-agenda",
          permission: () =>
            this.get_permission_user["minha-agenda"] &&
            this.get_permission_plan.includes("minha-agenda")
        },
        {
          icon: "mdi-calendar-edit-outline",
          name: "Agenda",
          route: "/agenda",
          permission: () =>
            this.get_permission_user["agenda"] &&
            this.get_permission_plan.includes("agenda")
        },
        {
          name: "divider",
          permission: () => false
        },
        {
          icon: "mdi-clipboard-list-outline",
          name: "Lista de compras",
          route: "/lista-de-compras",
          permission: () =>
            this.get_permission_user["lista-de-compras"] &&
            this.get_permission_plan.includes("lista-de-compra")
        },
        {
          name: "divider",
          permission: () => false
        },
        {
          icon: "mdi-library-outline",
          name: "Produtos",
          route: "/insumos",
          permission: () =>
            this.get_permission_user["produtos"] &&
            this.get_permission_plan.includes("produtos")
        },
        {
          icon: "mdi-account-circle-outline",
          name: "Clientes",
          route: "/clientes",
          permission: () =>
            this.get_permission_user["clientes"] &&
            this.get_permission_plan.includes("clientes")
        },
        {
          icon: "mdi-account-group-outline",
          name: "Colaboradores",
          route: "/funcionarios",
          permission: () =>
            this.get_permission_user["colaboradores"] &&
            this.get_permission_plan.includes("colaboradores")
        },
        {
          name: "divider",
          permission: () => false
        },
        {
          icon: "mdi-ballot-outline",
          name: "Contas",
          route: "/invoices",
          permission: () =>
            this.get_permission_user["contas"] &&
            this.get_permission_plan.includes("contas")
        },
        {
          name: "divider",
          permission: () => false
        },
        {
          icon: "mdi-receipt-text-check-outline",
          name: "Contabilidade dos Caixas",
          route: "/acompanhamento-de-caixa",
          permission: () =>
            this.get_permission_user["contabilidade-de-caixas"] &&
            this.get_permission_plan.includes("contabilidade")
        },
        {
          icon: "mdi-receipt-text-check-outline",
          name: "Relatório",
          route: "/caixas",
          permission: () =>
            this.get_permission_user["relatorio"] &&
            this.get_permission_plan.includes("relatorio-de-contabilidade")
        },
        {
          name: "divider",
          permission: () => false
        },
        {
          icon: "mdi-lock-outline",
          name: "Permissões",
          route: "/permissoes",
          permission: () =>
            this.get_permission_user["permissoes"] &&
            this.get_permission_plan.includes("permissoes")
        }
        // {
        //   icon: "mdi-cog-outline",
        //   name: "Configurações",
        //   route: "/configurar-loja",
        //   permission: () => this.get_permission_user["configuracoes"] && this.get_permission_plan.includes("configuracoes")
        // }
      ]
    };
  },
  computed: {
    ...mapGetters([
      "get_dashboard",
      "getLoggedUser",
      "get_permission_user",
      "get_permission_plan",
      "get_gestordepedidos",
      "get_funcionarios",
      "get_external_movimentations"
    ]),
    externalMovimentationsByTab() {
      let today = this.$moment().format("DD/MM/YYYY");
      let externalMovimentations = this.get_external_movimentations || [];
      let movimentations = [];

      // não tem permissão pra ver a agenda geral, carrega apenas a do usuário
      if (
        this.tabCalendar == 0 &&
        (!this.get_permission_user["agenda"] ||
          !this.get_permission_plan.includes("agenda"))
      ) {
        movimentations = externalMovimentations.filter(
          e => e.visit_date == today && e.executor._id == this.getLoggedUser._id
        );
      }

      //tab 1 sempre é a agenda pessoal
      if (this.tabCalendar == 1) {
        movimentations = externalMovimentations.filter(
          e => e.visit_date == today && e.executor._id == this.getLoggedUser._id
        );
      }

      //tem permissão, então carrega na tab 0 a agenda geral de hoje
      if (
        this.tabCalendar == 0 &&
        this.get_permission_user["agenda"] &&
        this.get_permission_plan.includes("agenda")
      ) {
        movimentations = externalMovimentations.filter(
          e => e.visit_date == today
        );
      }
      return movimentations;
    },
    notificationsUnreadeds() {
      return this.notifications.filter(n => !n.readed);
    },
    salesToday() {
      let today = this.$moment().format("YYYY-MM-DD");
      let sales = this.get_gestordepedidos.docs;
      let salesToday = sales.filter(
        sale => this.$moment(sale.created_at).format("YYYY-MM-DD") === today
      );
      return salesToday;
      // return sales;
    },
    salesLast30Days() {
      let sales = this.get_gestordepedidos.docs;
      // let limit = this.$moment().subtract(30, "days").format("YYYY-MM-DD");
      // let salesLast30Days = sales.filter(sale => {
      //   return this.$moment(sale.created_at).format("YYYY-MM-DD") >= limit;
      // })
      let days = [];
      let valuesForDays = [];
      for (let i = 30; i > 0; i--) {
        let day = this.$moment()
          .subtract(i, "days")
          .format("DD/MM");
        let salesForDay = sales.filter(
          sale => this.$moment(sale.created_at).format("DD/MM") === day
        );

        days.push(day);
        valuesForDays.push(salesForDay.length);
      }
      return {
        days,
        valuesForDays
      };
    },
    valuesPaid30Days() {
      let sales = this.get_gestordepedidos.docs;
      let limit = this.$moment()
        .subtract(30, "days")
        .format("YYYY-MM-DD");
      let salesLast30Days = sales.filter(sale => {
        return this.$moment(sale.created_at).format("YYYY-MM-DD") >= limit;
      });
      let paymentTypes = ["Dinheiro", "Pix", "Débito", "Crédito"];
      let dinheiro = 0;
      let pix = 0;
      let debito = 0;
      let credito = 0;
      salesLast30Days.forEach(sale => {
        let salePayments = sale.historico_de_pagamento;
        salePayments.forEach(payment => {
          if (payment.forma_de_pagamento == "pix") {
            pix += payment.valor;
          }
          if (payment.forma_de_pagamento == "dinheiro") {
            dinheiro += payment.valor;
          }
          if (payment.forma_de_pagamento == "debito") {
            debito += payment.valor;
          }
          if (payment.forma_de_pagamento == "credito") {
            credito += payment.valor;
          }
        });
      });

      return {
        paymentTypes,
        paymentValues: [dinheiro, pix, debito, credito]
      };
    },
    rankingUsers() {
      let users = this.get_funcionarios.docs;
      let userWithSales = users.map(user => {
        let sales = this.get_gestordepedidos.docs.filter(
          sale =>
            sale.caixa.funcionario && sale.caixa.funcionario._id == user._id
        );
        return {
          user,
          sales
        };
      });
      let sortedUsers = userWithSales.sort(
        (a, b) => b.sales.length - a.sales.length
      );
      return sortedUsers;
    }
  },
  filters: {
    typeFilter(value) {
      const types = [
        {
          name: "Visita",
          value: "visit"
        },
        {
          name: "Data limite",
          value: "limit_date"
        },
        {
          name: "Entrega",
          value: "delivery"
        },
        {
          name: "Ligação",
          value: "ligação"
        },
        {
          name: "E-mail",
          value: "email"
        },
        {
          name: "Whatsapp",
          value: "whatsapp"
        },
        {
          name: "Outros",
          value: "other"
        }
      ];
      let res = types.filter(i => i.value == value)[0].name || "";
      return res;
    }
  },
  methods: {
    ...mapActions([
      "listar_gestordepedidos",
      "listar_funcionarios",
      "listar_external_movimentations",
      "listar_my_external_movimentations"
    ]),
    testPing() {
      this.timeInit = Date.now();;
      let timeEnd;
      const self = this;
      axios.get("https://8.8.4.4")
        .then(() => {
          timeEnd = Date.now();;
          self.pingTime = timeEnd - self.timeInit;
          setTimeout(() => {
            // self.testPing();
          }, 1000);
        })
        .catch(() => {
          timeEnd = Date.now();;
          self.pingTime = timeEnd - self.timeInit;
          setTimeout(() => {
            // self.testPing();
          }, 1000);
        });
    },
    openEvent(event) {
      if (
        this.tabCalendar == 0 &&
        (!this.get_permission_user["agenda"] ||
          !this.get_permission_plan.includes("agenda"))
      ) {
        this.$router.push("/minha-agenda?event=" + event._id);
      }

      //tab 1 sempre é a agenda pessoal
      if (this.tabCalendar == 1) {
        this.$router.push("/minha-agenda?event=" + event._id);
      }

      //tem permissão, então carrega na tab 0 a agenda geral de hoje
      if (
        this.tabCalendar == 0 &&
        this.get_permission_user["agenda"] &&
        this.get_permission_plan.includes("agenda")
      ) {
        this.$router.push("/agenda?event=" + event._id);
      }
    },
    getColor(value) {
      const status = {
        waiting: "orange",
        done: "blue",
        canceled: "red",
        on_the_way: "green",
        lost: "red"
      };
      return status[value];
    },
    getStatus(value) {
      const status = {
        waiting: "Agendado",
        done: "Concluído",
        canceled: "Cancelado",
        on_the_way: "Em andamento",
        lost: "Perdido"
      };
      return status[value];
    },
    getIcon(value) {
      const status = {
        waiting: "mdi-clock-outline",
        done: "mdi-check-circle",
        canceled: "mdi-close-circle",
        on_the_way: "mdi-truck-delivery",
        lost: "mdi-alert-circle-outline"
      };
      return status[value];
    },
    saveStateTheme(dark) {
      if (dark) {
        localStorage.dark = "true";
      } else {
        localStorage.removeItem("dark");
      }
      this.chartSales = false;
      this.chartValues = false;
      this.showAllCharts = false;
      // this.$refs.chartValue.unmountChart();
      // this.$refs.chartSales.unmountChart();
      setTimeout(() => {
        this.chartValues = true;
        this.chartSales = true;
        // this.$refs.chartValue.montaChart();
        // this.$refs.chartSales.montaChart();
      }, 100);
      setTimeout(() => {
        this.showAllCharts = true;
      }, 200);
    },
    verifyTheme() {
      if (localStorage.dark === "true") {
        this.dark = true;
      }
    },
    notification_sound() {
      const audio = new Audio("call_in.mp3");
      audio.play();
    },
    abre_modal_view_notification(notify) {
      if (!notify.readed) {
        this.markNotificationAsReaded(notify);
      }
      const route = this.getLinkNotify(notify);
      this.$router.push(route.link);
    },
    getIconNotification(type) {
      const types = {
        new_comment: "mdi-comment-text-outline",
        new_sale: "mdi-cash-register",
        sale_updated: "mdi-satellite-variant",
        stock_low: "mdi-trending-down",
        stock_down: "mdi-basket-minus-outline",
        stock_up: "mdi-basket-plus-outline"
      };
      return types[type];
    },
    getTypeLinkByTypeNotification(notification) {
      const types = {
        invoice_payment: {
          title: "Lançamento de caixa",
          icon: "mdi-receipt-text-check-outline"
        },
        new_comment: {
          title: "Novo comentário",
          icon: "mdi-comment-text-outline"
        },
        employee_balance_added: {
          title: "Salário adicionado manualmente",
          icon: "mdi-account-plus-outline"
        },
        employee_balance_withdraw: {
          title: "Vale emitido",
          icon: "mdi-account-minus-outline"
        },
        sale_updated: {
          title: "Venda atualizada",
          icon: "mdi-cash-register"
        },
        stock_up: {
          title: "Estoque aumentado",
          icon: "mdi-cart-arrow-up"
        },
        stock_down: {
          title: "Estoque diminuido",
          icon: "mdi-cart-arrow-down"
        },
        stock_low: {
          title: "Estoque baixo",
          icon: "mdi-trending-down"
        },
        box_closed: {
          title: "Caixa fechado",
          icon: "mdi-cash-register"
        },
        box_opened: {
          title: "Caixa aberto",
          icon: "mdi-cash-register"
        },
        new_payment: {
          title: "Pagamento",
          icon: "mdi-cash-register"
        },
        new_comment_external_movimentation: {
          title: "Novo comentário",
          icon: "mdi-comment-text-outline"
        },
        new_external_movimentation: {
          title: "Novo agendamento",
          icon: "mdi-calendar-clock"
        },
        new_external_movimentation_viewed: {
          title: "O agendamento foi visualizado",
          icon: "mdi-calendar-clock"
        },
        external_movimentation_updated: {
          title: "Um agendamento foi atualizado",
          icon: "mdi-calendar-clock"
        },
        transaction_unsigned: {
          title: "Registro da conferência de caixa foi Cancelado",
          icon: "mdi-calendar-clock"
        },
        transaction_signed: {
          title: "Registro da conferência de caixa foi Aprovado",
          icon: "mdi-success"
        },
        seller_signup: {
          title: "Um vendedor se cadastrou",
          icon: "mdi-account-plus-outline"
        },
        "signature-flow-initiated": {
          title: "Um novo cliente se cadastrou",
          icon: "mdi-account-plus-outline"
        },
        "signature-flow-progress": {
          title: "Um cliente criou sua loja",
          icon: "mdi-account-plus-outline"
        }
      };
      if (!types[notification.type])
        return { title: notification.type, icon: "help_outline" };
      return types[notification.type];
    },
    getNotificationDescription(type) {
      const types = {
        new_comment: "Novo comentário",
        new_sale: "Nova venda",
        sale_updated: "Venda atualizada",
        stock_low: "Estoque baixo",
        stock_up: "Entrada de estoque",
        stock_down: "Saída de estoque"
      };
      return types[type];
    },
    listNotifications() {
      this.$run("notifications/list-my-notifications").then(res => {
        this.notifications = res.data;
      });
    },
    getLinkNotify(notification) {
      const types = {
        invoice_payment: {
          title: "Pagamento de conta",
          link: `/invoice?t_id=${notification.payload._id}`
        },
        new_comment: {
          title: "Novo comentário",
          link: `/gestordepedidos?t_id=${notification.payload._id}`
        },
        employee_balance_added: {
          title: "Salário adicionado manualmente",
          link: `/funcionarios?f_id=${
            notification.client ? notification.client._id : ""
          }&type=salario&e_id=${notification.payload._id}`
        },
        employee_balance_withdraw: {
          title: "Vale emitido",
          link: `/funcionarios?f_id=${notification.payload._id}&type=vale&extra=${notification.payload._id}`
        },
        sale_updated: {
          title: "Venda atualizada",
          link: `/gestordepedidos?t_id=${notification.payload._id}`
        },
        stock_up: {
          title: "Estoque aumentado",
          link: `/insumos?p_id=${notification.payload._id}&type=estoque`
        },
        stock_down: {
          title: "Estoque diminuido",
          link: `/insumos?p_id=${notification.payload._id}&type=estoque`
        },
        stock_low: {
          title: "Estoque baixo",
          link: `/insumos?p_id=${notification.payload._id}&type=estoque`
        },
        box_closed: {
          title: "Caixa fechado",
          link: `/acompanhamento-de-caixa?c_id=${notification.payload._id}`
        },
        box_opened: {
          title: "Caixa aberto",
          link: `/acompanhamento-de-caixa?c_id=${notification.payload._id}`
        },
        new_payment: {
          title: "Pagamento Recebido",
          link: `/gestordepedidos?t_id=${notification.payload._id}`
        },
        new_comment_external_movimentation: {
          title: "Novo comentário",
          link: `/agenda?t_id=${notification.payload._id}`
        },
        new_external_movimentation: {
          title: "Novo agendamento",
          link: `/agenda?e_id=${notification.payload._id}`
        },
        new_external_movimentation_viewed: {
          title: "O agendamento foi visualizado",
          link: `/agenda?e_id=${notification.payload._id}`
        },
        external_movimentation_updated: {
          title: "Um agendamento foi atualizado",
          link: `/minha-agenda?e_id=${notification.payload._id}`
        },
        transaction_unsigned: {
          title: "Registro da conferência de caixa foi Cancelado",
          link: `/acompanhamento-de-caixa?c_id=${notification.payload._id}`
        },
        transaction_signed: {
          title: "Registro da conferência de caixa foi Aprovado",
          link: `/acompanhamento-de-caixa?c_id=${notification.payload._id}`
        },
        seller_signup: {
          title: "Um vendedor se cadastrou",
          link: `https://erp.stonepro.com.br/#/sellers?c_id=${notification.payload._id}`
        },
        "signature-flow-initiated": {
          title: "Um novo cliente se cadastrou",
          link: `https://erp.stonepro.com.br/#/funcionarios?c_id=${notification.payload._id}`
        },
        "signature-flow-progress": {
          title: "Um cliente criou sua loja",
          link: `https://erp.stonepro.com.br/#/funcionarios?c_id=${notification.payload._id}`
        }
      };
      return types[notification.type];
    },
    markNotificationAsReaded(notification) {
      this.$run("notifications/mark-as-readed", { _id: notification._id }).then(
        res => {
          this.listNotifications();
        }
      );
    },
    mountDriver() {
      this.driver = driver({
        showProgress: true,
        steps: [
          {
            element: "#container-menus",
            popover: {
              title: "Bem vindo",
              description:
                "Na dashboard você visualiza os menus que você tem acesso.",
              doneBtnText: "Sair",
              closeBtnText: "Fechar",
              nextBtnText: "Próximo",
              prevBtnText: ""
            }
          },
          {
            element: "#options-container",
            popover: {
              title: "Botões de ação",
              description: "Ações da página sempre aparecerão aqui.",
              doneBtnText: "Sair",
              closeBtnText: "Fechar",
              nextBtnText: "Próximo",
              prevBtnText: "Anterior"
            }
          },
          {
            element: "#notifications-container",
            popover: {
              title: "Notificações",
              description:
                "Quando algo precisar da sua atenção, você será avisado com uma notificação aqui nesse botão.",
              doneBtnText: "Sair",
              closeBtnText: "Fechar",
              nextBtnText: "Próximo",
              prevBtnText: "Anterior"
            }
          }
          // { element: '.top-nav', popover: { title: 'Title', description: 'Description' } },
          // { element: '.sidebar', popover: { title: 'Title', description: 'Description' } },
          // { element: '.footer', popover: { title: 'Title', description: 'Description' } },
        ]
      });
    },
    driverStart() {
      this.driver.drive();
    }
  },
  created() {
    this.verifyTheme();
    this.listar_gestordepedidos();
    this.listNotifications();
    this.mountDriver();
  },
  mounted() {
    setTimeout(() => {
      this.showWelcome = false;
    }, 3000);
    this.testPing();
    this.listar_funcionarios();
    this.listar_external_movimentations();
    this.$refs.modalSub.verifySub();
    if (!sessionStorage.nt || sessionStorage.nt) {
      if (Notification.permission === "granted") {
        sessionStorage.nt = "ok";
        const messaging = getMessaging();
        getToken(messaging, {
          vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY
        }).then(currentToken => {
          if (currentToken) {
            this.$run("notifications/register-token", {
              notification_token: currentToken
            }).catch(res => {
              console.log("erro no token", res);
            });
          } else {
            alert(
              "você recusou o envio de notificações, para receber nossos fluxos de notificação, acesse as configurações do seu navegador e permita o envio de notificações."
            );
          }
          onMessage(messaging, payload => {
            const { data } = payload;
            this.listNotifications();
            this.notification_sound();
          });
        });
      } else {
        Notification.requestPermission()
          .then(async permission => {
            if (permission === "granted") {
              const messaging = getMessaging();
              getToken(messaging, {
                vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY
              }).then(currentToken => {
                if (currentToken) {
                  this.$run("notifications/register-token", {
                    notification_token: currentToken
                  })
                    .then(() => {
                      sessionStorage.nt = "ok";
                    })
                    .catch(res => {
                      console.log("erro no token", res);
                    });
                } else {
                  alert(
                    "você recusou o envio de notificações, para receber nossos fluxos de notificação, acesse as configurações do seu navegador e permita o envio de notificações."
                  );
                }
                onMessage(messaging, payload => {
                  this.listNotifications();
                  this.notification_sound();
                });
              });
            }
          })
          .catch(err => {
            console.log("wtf", err);
          });
      }
    }
  }
};
</script>

<style>
.empty {
  transition: transform 0.3s ease;
}
.empty:hover {
  transform: scale(1.2);
}
.message {
  width: 150px;
  /* animation: digitando 0.2s ease; */
  white-space: nowrap;
  overflow: hidden;

  position: absolute;
  top: 50%;
  left: 45%;
  /* transform: translate(-50%, -50%); */
}

@keyframes digitando {
  from {
    width: 0;
  }
}

.fonte-color {
  color: #075059;
}
.icon-show-balance {
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px dashed #000;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.divider-balance {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #000;
}
.unreaded-notification {
  animation: blinker 1s linear infinite;
}
.item-menu-mini {
  transition: transform 0.3s ease;
}
.item-menu-mini:hover {
  transform: scale(1.1);
  font-weight: bold;
}
@keyframes blinker {
  50% {
    opacity: 0;
    margin-top: 5px;
  }
}
</style>
